import React from "react";
import { People } from "../../models";

interface Props {
  person: People;
  household_name: String;
}

export const PersonBox: React.FC<Props> = ({ person, household_name }) => {
  return (
    <div className="person_box">
      <div className="box-for">
        <h3>Family Name </h3>
        <h3>{household_name}</h3>
      </div>
      <div className="box-for">
        <h3>Child or Adult</h3> <h3>{person.CA}</h3>
      </div>
      <div className="box-for">
        <h3>First Name</h3> <h3>{person.first_name}</h3>
      </div>
      <div className="box-for">
        <h3>Age </h3>
        <h3>{person.age}</h3>
      </div>
      <div className="box-for">
        <h3>Height Feet </h3>
        <h3>{person.height_feet}</h3>
      </div>
      <div className="box-for">
        <h3>Height Inches </h3>
        <h3>{person.height_inches}</h3>
      </div>
      <div className="box-for">
        <h3>Gender</h3> <h3>{person.sex}</h3>
      </div>
      <div className="box-for">
        <h3>Shirt Size</h3> <h3>{person.shirt_size}</h3>
      </div>
      <div className="box-for">
        <h3>Pants Size </h3>
        <h3>{person.pants_size}</h3>
      </div>
      <div className="box-for">
        <h3>Shoe Size </h3>
        <h3>{person.shoe_size}</h3>
      </div>
      <div className="box-for">
        <h3>Bra Size</h3> <h3>{person.bra_size}</h3>
      </div>
      {/* <div className="box-for">
        <h3>Hat Cloat and Gloves</h3> <h3>{person.hatCoatGloves}</h3>
      </div> */}
      <div className="box-for">
        <h3>Anything Else </h3>
        <h3>{person.anything_else}</h3>
      </div>
      <br />
    </div>
  );
};
