import { t } from "i18next";
import React, { useContext } from "react";
import { GiBodyHeight } from "react-icons/gi";
import { ContextMobile } from "../../../HomePage";
import RequiredStar from "./RequiredStar";

const PersonShared = () => {
  const { handlePeopleChange, people, selectedPersonIndex } =
    useContext<any>(ContextMobile);

  return (
    <>
      <div>{t("child_or_adult")}</div>
      <RequiredStar />
      <label>
        <input
          type="radio"
          id="child-adult"
          className="radio_adult"
          name="child-adult"
          value="adult"
          checked={people[selectedPersonIndex].CA === "adult"}
          onChange={(e) => handlePeopleChange({ CA: e.currentTarget.value })}
        />
        {t("adult")}
        <input
          type="radio"
          id="child-adult"
          className="radio_child"
          name="child-adult"
          value="child"
          checked={people[selectedPersonIndex].CA === "child"}
          onChange={(e) => handlePeopleChange({ CA: e.currentTarget.value })}
        />
        {t("child")}
      </label>
      {people[selectedPersonIndex].CA && (
        <>
          <div className="sections">
            <div>{t("name")}</div>
            <RequiredStar />
            <input
              type="text"
              id="first-name"
              placeholder={t("name")}
              value={people[selectedPersonIndex].first_name}
              onChange={(e) =>
                handlePeopleChange({ first_name: e.currentTarget.value })
              }
            />
          </div>
          <div className="sections">
            <div>{t("age")}</div>
            <input
              type="text"
              id="age"
              placeholder={t("age")}
              value={people[selectedPersonIndex].age}
              onChange={(e) =>
                handlePeopleChange({ age: e.currentTarget.value })
              }
            />
          </div>
          <div>{t("height")}</div>

          <div className="sections">
            <label>
              <input
                type="radio"
                id="meters-feet"
                className="radio_height"
                name="meters-feet"
                value="feet"
                checked={!(people[selectedPersonIndex].MF === "meters")}
                onChange={(e) =>
                  handlePeopleChange({ MF: e.currentTarget.value })
                }
              />
              {t("Feet")}
              <input
                type="radio"
                id="meters-feet"
                className="radio_meters"
                name="meters-feet"
                value="meters"
                checked={people[selectedPersonIndex].MF === "meters"}
                onChange={(e) =>
                  handlePeopleChange({ MF: e.currentTarget.value })
                }
              />
              {t("Centimeters")}
            </label>
            {people[selectedPersonIndex].MF === "meters" ? (
              <div className="clothes-container align-vert">
                <GiBodyHeight className="clothes-icons" />{" "}
                <input
                  type="text"
                  id="height-feet"
                  placeholder={t("cm")}
                  value={people[selectedPersonIndex].height_feet}
                  onChange={(e) =>
                    handlePeopleChange({ height_feet: e.currentTarget.value })
                  }
                />
              </div>
            ) : (
              <div className="clothes-container align-vert">
                <GiBodyHeight className="clothes-icons" />{" "}
                <input
                  type="text"
                  id="height-feet"
                  placeholder={t("feet")}
                  value={people[selectedPersonIndex].height_feet}
                  onChange={(e) =>
                    handlePeopleChange({ height_feet: e.currentTarget.value })
                  }
                />
                <input
                  type="text"
                  id="height-inches"
                  placeholder={t("inches")}
                  value={people[selectedPersonIndex].height_inches}
                  onChange={(e) =>
                    handlePeopleChange({ height_inches: e.currentTarget.value })
                  }
                />
              </div>
            )}
          </div>
          <div className="sections">
            <div>{t("male_or_female_clothing")}</div>
            <RequiredStar />
            <label>
              <input
                type="radio"
                id="sex"
                className="radio_sex_male"
                name="sex"
                value="male"
                checked={people[selectedPersonIndex].sex === "male"}
                onChange={(e) =>
                  handlePeopleChange({ sex: e.currentTarget.value })
                }
              />
              {t("male")}
              <input
                type="radio"
                id="sex"
                className="radio_sex_female"
                name="sex"
                value="female"
                checked={people[selectedPersonIndex].sex === "female"}
                onChange={(e) =>
                  handlePeopleChange({ sex: e.currentTarget.value })
                }
              />
              {t("female")}
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default PersonShared;
