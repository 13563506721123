import React, { useEffect, useState } from "react";
import API from "../../API";
import OrdersList from "./OrdersList";

export const PastOrders = () => {
  const [allOrders, setAllOrders] = useState<any[]>([]);

  useEffect(() => {
    API.fetchHouseholds().then((data) => {
      setAllOrders(data);
    });
  }, []);
  return <OrdersList households={allOrders} />;
};
