import React, { useEffect, useState, createContext } from "react";
//import { useOutletContext } from "react-router-dom";

import API from "../../API";

import { HouseholdWith } from "../../models";
import OrdersList from "./OrdersList";

// Components

const ContextAdmin = createContext<any>(null);
let ContextValue: any;

interface Props {}

const Admin: React.FC<Props> = (props) => {
  //const { handleToOrder } = useOutletContext<any>();
  const [repullHouseholds, setRepullHouseholds] = useState<string>("");
  const [uncompleteOrder, setUncompleteOrder] = useState<any[]>([]);

  useEffect(() => {
    API.fetchUncomplete().then((data) => setUncompleteOrder(data));
  }, [repullHouseholds]);

  const handleCompleted = (household: HouseholdWith) => {
    API.updateHousehold(household.id, { is_completed: true });
    setRepullHouseholds(Math.random().toString());
  };

  const handleUpdateRepull = (householdId: string, note: string) => {
    API.createContactNote(householdId, note);

    setTimeout(() => {
      setRepullHouseholds(Math.random().toString());
    }, 5000);
  };

  const handleDelete = (id: string) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this entry?"
    );

    if (confirmation) {
      API.deleteHousehold(id);
      setRepullHouseholds(Math.random().toString());
    }
  };

  ContextValue = {
    handleCompleted,
    handleDelete,
    handleUpdateRepull,
  };

  return (
    <ContextAdmin.Provider value={ContextValue}>
      <div className="admin_main">
        <div className="household_list">
          <h3>Pending Orders</h3>

          <OrdersList households={uncompleteOrder} />
        </div>
      </div>
    </ContextAdmin.Provider>
  );
};

export { Admin as default, ContextAdmin };
