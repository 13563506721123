import React from "react";

import { Link } from "react-router-dom";

interface Props {
  handleLogout: any;
}

const Header: React.FC<Props> = ({ handleLogout }) => {
  //const { handleLogout } = useOutletContext<any>();

  return (
    <div className="header_container">
      <Link className="head_link" to="/myadmin">
        Pending
      </Link>
      <Link className="head_link" to="/myadmin/stats">
        Stats
      </Link>
      <Link className="head_link" to="/myadmin/pastorders">
        Past Orders
      </Link>
      <Link className="head_link" to="/myadmin/accounts">
        Accounts
      </Link>

      <button className="move_right" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
};

export default Header;
