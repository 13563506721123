import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import HomePage from "./HomePage";
import AdminLogin from "./components/Admin/AdminLogin";
import Stats from "./components/Admin/Stats";
import Admin from "./components/Admin/Admin";
import { Order } from "./components/Admin/Order";
import { PastOrders } from "./components/Admin/PastOrders";
import { Accounts } from "./components/Admin/Accounts";

interface Props {}

function App(props: Props) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/myadmin" element={<AdminLogin />}>
          <Route index element={<Admin />} />
          <Route path="stats" element={<Stats />} />
          <Route path=":id" element={<Order />} />
          <Route path="pastorders" element={<PastOrders />} />
          <Route path="accounts" element={<Accounts />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
