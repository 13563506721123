import React, { useEffect, useState } from "react";
import API from "../../API";

export const Accounts = () => {
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    API.getUsers().then((data) => {
      console.log(data);
      setUsers(data);
    });
  }, []);

  const createNewUser = async (e: any) => {
    e.preventDefault();

    const response = await API.createUser(
      e.target[0].value,
      e.target[1].value,
      e.target[2].value,
      e.target[3].value
    );

    console.log(response);

    if (response.status === 201) {
      setShowAdd(false);
      alert("Created!");
    } else {
      alert("failed, please try again");
    }
  };

  return (
    <>
      <div className="btn" onClick={() => setShowAdd(true)}>
        Create New User
      </div>
      {users.map((user) => {
        return <div>{user.username}</div>;
      })}
      {showAdd && (
        <form onSubmit={(e) => createNewUser(e)}>
          <h2>Please provide the following information:</h2>
          <div>
            <label>Email</label>
            <input type="text" required />
          </div>
          <div>
            <label>Username</label>
            <input type="text" required />
          </div>
          <div>
            <label>Password</label>
            <input type="password" required />
          </div>
          <div>
            <label>Is this an admin user?</label>
            <label>True:</label>
            <input type="radio" name="super" value="true" />
            <label>False:</label>
            <input type="radio" name="super" value="false" />
          </div>
          <button type="submit">Create User</button>
        </form>
      )}
    </>
  );
};
