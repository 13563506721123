import cookies from "js-cookie";
import { totalPackage } from "./models";

const rootUrl = "";

//getUrl || "https://mobileclothes.org";

let csrftoken: any = cookies.get("csrftoken");

const API = {
  repullCSRF: () => {
    csrftoken = cookies.get("csrftoken");
  },
  processOrder: async (totalPackage: totalPackage) => {
    const endpoint = `${rootUrl}/api/processrequest`;

    return await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(totalPackage),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "X-CSRFToken": csrftoken,
      },
    });
  },
  fetchHouseholds: async () => {
    const endpoint = `${rootUrl}/api/gethouseholds`;
    return await (
      await fetch(endpoint, {
        credentials: "include",
      })
    ).json();
  },
  fetchOneHousehold: async (id: any) => {
    const endpoint = `${rootUrl}/api/getonehousehold?id=${id}`;
    return await (
      await fetch(endpoint, {
        credentials: "include",
      })
    ).json();
  },
  fetchHousesByAddress: async (address: string) => {
    const endpoint = `${rootUrl}/api/getonehousehold?address=${address}`;
    return await (
      await fetch(endpoint, {
        credentials: "include",
      })
    ).json();
  },
  fetchOrder: async (id: any) => {
    const endpoint = `${rootUrl}/api/getorder?id=${id}`;
    return await (
      await fetch(endpoint, {
        credentials: "include",
      })
    ).json();
  },
  fetchUncomplete: async () => {
    const endpoint = `${rootUrl}/api/getuncompletehouseholds`;
    return await (
      await fetch(endpoint, {
        credentials: "include",
      })
    ).json();
  },
  fetchPersons: async (id: any) => {
    const endpoint = `${rootUrl}/api/getpersons?id=${id}`;
    return await (await fetch(endpoint)).json();
  },
  fetchStats: async () => {
    const endpoint = `${rootUrl}/api/getstats`;
    return await (await fetch(endpoint)).json();
  },
  updateHousehold: async (householdId: string, changes: Object) => {
    const endpoint = `${rootUrl}/api/updatehousehold?id=${householdId}`;

    return await fetch(endpoint, {
      method: "PATCH",
      body: JSON.stringify(changes),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  },
  saveHouseAndPersons: async (
    householdId: string,
    household: Object,
    persons: Object
  ) => {
    const endpoint = `${rootUrl}/api/updatehouseholdandpersons?id=${householdId}`;

    return await await fetch(endpoint, {
      method: "PATCH",
      body: JSON.stringify([household, persons]),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  },
  createContactNote: async (householdId: any, note: string) => {
    const endpoint = `${rootUrl}/api/createcontactnote`;

    return await await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ household_id: householdId, note: note }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  },
  fetchContactNotes: async (id: any) => {
    const endpoint = `${rootUrl}/api/getcontactnotes?id=${id}`;
    return await (await fetch(endpoint)).json();
  },
  deleteContactNote: async (note_id: any) => {
    const endpoint = `${rootUrl}/api/deletecontactnote?note_id=${note_id}`;
    return await await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  },
  deleteHousehold: async (id: string) => {
    const endpoint = `${rootUrl}/api/deletehousehold?id=${id}`;
    return await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    });
  },
  login: async (username: string, password: string) => {
    const endpoint = `${rootUrl}/api/auth/login`;
    return await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    });
  },
  logout: async () => {
    const endpoint = `${rootUrl}/api/auth/logout`;
    return await fetch(endpoint, { method: "POST" });
  },
  verify: async () => {
    const endpoint = `${rootUrl}/api/auth/user`;
    return await fetch(endpoint);
  },
  createUser: async (
    email: string,
    username: string,
    password: string,
    isSuperUser: boolean
  ) => {
    const endpoint = `${rootUrl}/api/auth/register`;
    return await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      mode: "same-origin",
      body: JSON.stringify({
        email,
        username,
        password,
        isSuperUser,
      }),
    });
  },
  url: () => {
    return rootUrl;
  },
  getUsers: async () => {
    const endpoint = `${rootUrl}/api/auth/getusers`;
    return await (
      await fetch(endpoint, {
        credentials: "include",
        headers: {
          "X-CSRFToken": csrftoken,
        },
      })
    ).json();
  },
};

export default API;
