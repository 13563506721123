import React from "react";
import { HouseholdWith, People } from "../../models";
import FamilyBox from "./FamilyBox";
import { PersonBox } from "./PersonBox";

interface Props {
  componentRef: any;
  currentHousehold: HouseholdWith;

  persons: any[];
}

const PrintPopout: React.FC<Props> = ({
  componentRef,
  currentHousehold,

  persons,
}) => {
  return (
    <div
      className="popup"
      // style={dontDisplayHousehold ? { display: "none" } : {}}
      style={{ display: "none" }}
    >
      <div className="popup-inner-admin" ref={componentRef}>
        <FamilyBox household={currentHousehold} />
        {persons.map((person: People) => {
          return (
            <PersonBox
              key={person.id}
              person={person}
              household_name={currentHousehold.household_name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PrintPopout;
