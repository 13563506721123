import React from "react";

interface Props {
  persons: any;
  setPersons: any;
}

export const PersonEdit: React.FC<Props> = ({ persons, setPersons }) => {
  const handleEditPerson = (changes: any, i: number) => {
    const newPeople = [...persons];
    //const index = newPeople.findIndex((r) => r.id === selectedPersonId);
    newPeople[i] = { ...newPeople[i], ...changes };
    setPersons(newPeople);
  };

  return (
    <div>
      {persons.map((person: any, i: any) => {
        return (
          <div className="person_box">
            <div className="box-for">
              <h3>Child or Adult</h3>
              <div className="radio_box">
                <label>Child</label>
                <input
                  type="radio"
                  value="child"
                  checked={person.child_or_adult === "child"}
                  onChange={(e) =>
                    handleEditPerson(
                      { child_or_adult: e.currentTarget.value },
                      i
                    )
                  }
                />
                <label>Adult</label>
                <input
                  type="radio"
                  value="adult"
                  checked={person.child_or_adult === "adult"}
                  onChange={(e) =>
                    handleEditPerson(
                      { child_or_adult: e.currentTarget.value },
                      i
                    )
                  }
                />
              </div>
            </div>
            <div className="box-for">
              <h3>First Name</h3>{" "}
              <input
                type="text"
                value={person.first_name}
                onChange={(e) =>
                  handleEditPerson({ first_name: e.currentTarget.value }, i)
                }
              />
            </div>
            <div className="box-for">
              <h3>Age </h3>
              <input
                type="text"
                value={person.age}
                onChange={(e) =>
                  handleEditPerson({ age: e.currentTarget.value }, i)
                }
              />
            </div>
            <div className="box-for">
              <h3>Height Feet </h3>
              <input
                type="text"
                value={person.height_feet}
                onChange={(e) =>
                  handleEditPerson({ height_feet: e.currentTarget.value }, i)
                }
              />
            </div>
            <div className="box-for">
              <h3>Height Inches </h3>
              <input
                type="text"
                value={person.height_inches}
                onChange={(e) =>
                  handleEditPerson({ height_inches: e.currentTarget.value }, i)
                }
              />
            </div>
            <div className="box-for">
              <h3>Gender</h3>{" "}
              <div className="radio_box">
                <label>Male</label>
                <input
                  type="radio"
                  value="male"
                  checked={person.sex === "male"}
                  onChange={(e) =>
                    handleEditPerson({ sex: e.currentTarget.value }, i)
                  }
                />
                <label>Female</label>
                <input
                  type="radio"
                  value="female"
                  checked={person.sex === "female"}
                  onChange={(e) =>
                    handleEditPerson({ sex: e.currentTarget.value }, i)
                  }
                />
              </div>
            </div>
            <div className="box-for">
              <h3>Shirt Size</h3>{" "}
              <input
                type="text"
                value={person.shirt_size}
                onChange={(e) =>
                  handleEditPerson({ shirt_size: e.currentTarget.value }, i)
                }
              />
            </div>
            <div className="box-for">
              <h3>Pants Size </h3>
              <input
                type="text"
                value={person.pants_size}
                onChange={(e) =>
                  handleEditPerson({ pants_size: e.currentTarget.value }, i)
                }
              />
            </div>
            <div className="box-for">
              <h3>Shoe Size </h3>
              <input
                type="text"
                value={person.shoe_size}
                onChange={(e) =>
                  handleEditPerson({ shoe_size: e.currentTarget.value }, i)
                }
              />
            </div>
            {person.Gender === "female" &&
              person.child_or_adult === "adult" && (
                <div className="box-for">
                  <input
                    type="text"
                    value={person.bra_size}
                    onChange={(e) =>
                      handleEditPerson({ bra_size: e.currentTarget.value }, i)
                    }
                  />
                </div>
              )}

            <div className="box-for">
              <h3>Anything Else </h3>
              <textarea
                value={person.anything_else}
                onChange={(e) =>
                  handleEditPerson({ anything_else: e.currentTarget.value }, i)
                }
              />
            </div>
            <br />
          </div>
        );
      })}
    </div>
  );
};
