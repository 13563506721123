import React, { useEffect, useRef, useState } from "react";
import {
  useOutletContext,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import API from "../../API";
import { useReactToPrint } from "react-to-print";
import PrintPopout from "./PrintPopout";
import OrdersList from "./OrdersList";
import { EditPopout } from "./EditPopout";

export const Order = () => {
  const { id } = useParams();
  const { currentHousehold, setCurrentHousehold, handleEditHousehold } =
    useOutletContext<any>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [contactNotes, setContactNotes] = useState<any[]>([]);
  const [newContactNote, setNewContactNote] = useState<string>("");
  const [repull, setRepull] = useState<number>();
  const [persons, setPersons] = useState<any[]>([]);
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const [previousOrders, setPreviousOrders] = useState<any[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  // React to print Reference
  const componentRef = useRef<any>();

  useEffect(() => {
    API.fetchOrder(id).then((data) => {
      setContactNotes(data[0]);
      setPersons(data[1]);
      setCurrentHousehold(data[2]);
      setPreviousOrders(data[3]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repull, location.pathname]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      .popup-inner-admin {
        overflow-y: none;
      }
    `,
    onBeforePrint: () => {
      setShowPrint(true);
    },
    onAfterPrint: () => {
      setShowPrint(false);
    },
  });

  const runPrint = () => {
    setShowPrint(true);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  const addContactNode = (): void => {
    API.createContactNote(id, newContactNote);
    setShowAdd(false);
    setNewContactNote("");

    setTimeout(() => {
      setRepull(Math.random());
    }, 2000);
  };

  const deleteContactNote = (noteId: any) => {
    API.deleteContactNote(noteId);

    setTimeout(() => {
      setRepull(Math.random());
    }, 2000);
  };

  const updatePending = (updates: any) => {
    handleEditHousehold(id, updates);

    setTimeout(() => {
      setRepull(Math.random());
    }, 2000);
  };

  const editOrder = (id: any) => {
    console.log(persons);
    setEdit(true);
  };

  const deleteOrder = (id: any) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this entry?"
    );

    if (confirmation) {
      API.deleteHousehold(id);
      navigate("/myadmin");
    }
  };

  return (
    <div>
      <div className="right_side">
        <div className="btn" onClick={runPrint}>
          Print
        </div>
        {currentHousehold.pending_action !== "completed" && (
          <div
            className="btn"
            onClick={() =>
              updatePending({
                pending_action:
                  currentHousehold.pending_action === "pending contact"
                    ? "ready for delivery"
                    : "completed",
                is_completed:
                  currentHousehold.pending_action === "pending contact" ? 0 : 1,
                order_completed: new Date(Date.now()),
              })
            }
          >
            Progress order
          </div>
        )}
        {!showAdd && (
          <div className="btn right_side" onClick={() => setShowAdd(true)}>
            Add Contact Note
          </div>
        )}
        <div className="btn" onClick={() => editOrder(currentHousehold.id)}>
          Edit
        </div>
        <div className="btn" onClick={() => deleteOrder(currentHousehold.id)}>
          Delete Order
        </div>
      </div>

      <h4>Family Name: {currentHousehold.household_name}</h4>
      <div>Current Status: {currentHousehold.pending_action}</div>
      <div>Address: {currentHousehold.street_address}</div>
      <div>Phone Number: {currentHousehold.phone_number}</div>
      <div>
        Contact Notes:{" "}
        {contactNotes.map((note) => {
          return (
            <div>
              <div>
                {note.user} - {note.note}
              </div>
              <div onClick={() => deleteContactNote(note.id)}>(x)</div>
            </div>
          );
        })}
      </div>

      {showAdd && (
        <div>
          <textarea
            name="paragraph_text"
            cols={50}
            rows={10}
            id="contact_notes"
            value={newContactNote}
            onChange={(e) => setNewContactNote(e.target.value)}
          ></textarea>
          <div className="btn" onClick={addContactNode}>
            Save
          </div>
          <div className="btn" onClick={() => setShowAdd(false)}>
            Cancel
          </div>
        </div>
      )}
      <h3>Family Members</h3>
      {persons.map((person, i) => {
        return (
          <div className="flexbox_pad">
            <div>{i + 1 + ") " + person.first_name}</div>
            <div> {person.sex}</div>
            <div>{person.age}</div>
          </div>
        );
      })}
      <h3>History</h3>
      <OrdersList households={previousOrders} />
      {showPrint && (
        <PrintPopout
          componentRef={componentRef}
          currentHousehold={currentHousehold}
          persons={persons}
        />
      )}
      {edit && (
        <EditPopout
          currentHousehold={currentHousehold}
          setCurrentHousehold={setCurrentHousehold}
          persons={persons}
          setPersons={setPersons}
          setEdit={setEdit}
        />
      )}
    </div>
  );
};
