import React from "react";
import { HouseholdWith } from "../../models";
import { useOutletContext } from "react-router-dom";

interface Props {
  households: HouseholdWith[];
}

const formatDate = (timestamp: string) => {
  const match = timestamp.match(
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{6})Z$/
  );

  if (!match) {
    return "Invalid timestamp format";
  }

  const year = match[1];
  const month = match[2];
  const day = match[3];
  let hour = parseInt(match[4], 10);
  const minute = match[5];
  //const second = match[6];

  // Convert UTC time to MST (UTC - 7 hours)
  const converstion = 6;
  let mstHour = hour - converstion;
  if (mstHour < 0) {
    mstHour += 24;
  }

  let period = "AM";
  if (mstHour >= 12) {
    period = "PM";
    if (mstHour > 12) {
      mstHour -= 12;
    }
  }
  if (mstHour === 0) {
    mstHour = 12;
  }

  const formattedDate = `${month}-${day}-${year} ${mstHour}:${minute} ${period} MST`;

  return formattedDate;
};

const OrdersList: React.FC<Props> = ({ households }) => {
  const { handleToOrder } = useOutletContext<any>();

  return (
    <div>
      {households.map((household: HouseholdWith, i: number) => {
        return (
          <div className="household_list_item" key={household.id}>
            <div
              className="family_names"
              onClick={() => handleToOrder(household)}
            >
              {household.household_name}
            </div>
            {household.order_date !== undefined && (
              <div>Date submitted - {formatDate(household.order_date)}</div>
            )}

            {household.pending_action !== undefined && (
              <div>To do - {household.pending_action}</div>
            )}
            {/* <button onClick={() => handleCompleted(household)}>
              Move to Completed Orders
            </button>

            <button onClick={() => handlePrintOutside(i)}>Print</button> */}
          </div>
        );
      })}
    </div>
  );
};

export default OrdersList;
