//import { v4 as uuidv4 } from "uuid";
import { Household } from "./models";
//import { v4 as uuidv4 } from "uuid";

// export const samplePerson: People = {
//   id: uuidv4(),
//   CA: "",
//   first_name: "",
//   age: "",
//   height_feet: "",
//   height_inches: "",
//   sex: "",
//   shirt_size: "",
//   shoe_size: "",
//   inseam_length: "",
//   waist: "",
//   bra_size: "",
//   pants_size: "",
//   anything_else: "",
// };

export const sampleHouseHold: Household = {
  family_name: "",
  street_address: "",
  zip_code: "",
  phone_number: "",
  email_address: "",
  country_of_origin: "",
};
