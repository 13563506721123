import React, { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import API from "../../API";

interface Props {}

const Stats: React.FC<Props> = (props) => {
  //const navigate = useNavigate();
  const [stats, setStats] = useState();

  // useEffect(() => {
  //   API.verify().then((response) => {
  //     response.status !== 200 && navigate("/adminlogin");
  //   });
  // }, [navigate]);

  useEffect(() => {
    API.fetchStats().then((data) => setStats(data));
  }, []);

  return (
    <>
      <div>{stats}</div>
    </>
  );
};

export default Stats;
