import React, { useEffect, useState } from "react";
import cookies from "js-cookie";

import API from "../../API";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";

interface Props {}

const AdminLogin: React.FC<Props> = (props) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [currentHousehold, setCurrentHousehold] = useState<any>({
    id: "",
    household_name: "",
    street_address: "",
    zip_code: "",
    phone_number: "",
    country_of_origin: "",
    people: [],
    order_date: "",
    pending_action: "",
  });
  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault();

    const response = await API.login(e.target[0].value, e.target[1].value);

    if (response.status === 200) {
      setLoggedIn(true);
      API.repullCSRF();
    } else {
      alert("failed, please try again");
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    cookies.remove("csrftoken");
    API.logout();
  };

  const handleEditHousehold = (id: any, changes: any) => {
    API.updateHousehold(id, changes);
  };

  const handleToOrder = (household: any) => {
    setCurrentHousehold(household);
    navigate(`/myadmin/${household.id}`);
  };

  useEffect(() => {
    API.verify().then((response: any) => {
      response.status === 200 && setLoggedIn(true);
    });
  }, []);

  return loggedIn ? (
    <div>
      <Header handleLogout={handleLogout} />
      <div className="admin_container">
        <Outlet
          context={{
            currentHousehold,
            setCurrentHousehold,
            handleEditHousehold,
            handleToOrder,
          }}
        />
      </div>
    </div>
  ) : (
    <div className="admin_login_container">
      <h1>Admin Login</h1>
      <form onSubmit={(e) => handleLogin(e)}>
        <div>
          <label>Username</label>
          <input type="text" required />
        </div>
        <div>
          <label>Password</label>
          <input type="password" required />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
