import { t } from "i18next";
import React from "react";
import { Household } from "../../models";

interface Props {
  household: Household;
  handleHouseHoldChange: (changes: any) => void;
  houseInfoRef: any;
  required: boolean;
}

const FamilyInputs: React.FC<Props> = ({
  household,
  handleHouseHoldChange,
  houseInfoRef,
  required,
}) => {
  return (
    <div
      className="household_information"
      style={{ display: "contents" }}
      ref={houseInfoRef}
    >
      <div className="input-container">
        <h2>{t("family_name")}</h2>
        {required && <div className="required-star">*</div>}
        <input
          type="text"
          id="family-name"
          required
          value={household.family_name}
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            handleHouseHoldChange({ family_name: e.currentTarget.value })
          }
        />
      </div>
      <div className="input-container">
        <h2>{t("street_address")}</h2>
        {required && <div className="required-star">*</div>}
        <input
          type="text"
          id="street-address"
          required
          value={household.street_address}
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            handleHouseHoldChange({ street_address: e.currentTarget.value })
          }
        />
      </div>
      <div className="input-container">
        <h2>{t("zip_code")}</h2>
        {required && <div className="required-star">*</div>}
        <input
          type="text"
          id="zip-code"
          required
          value={household.zip_code}
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            handleHouseHoldChange({ zip_code: e.currentTarget.value })
          }
        />
      </div>
      <div className="input-container">
        <h2>{t("phone_number")}</h2>
        {required && <div className="required-star">*</div>}
        <input
          type="text"
          id="phone-number"
          required
          value={household.phone_number}
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            handleHouseHoldChange({ phone_number: e.currentTarget.value })
          }
        />
      </div>
      <div className="input-container">
        <h2>{t("email_address")}</h2>
        {required && <div className="required-star">*</div>}
        <input
          type="text"
          id="email-address"
          required
          value={household.email_address}
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            handleHouseHoldChange({ email_address: e.currentTarget.value })
          }
        />
      </div>
      <div className="input-container">
        <h2>{t("country_of_origin")}</h2>
        <input
          type="text"
          id="country-of-origin"
          value={household.country_of_origin}
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            handleHouseHoldChange({ country_of_origin: e.currentTarget.value })
          }
        />
      </div>
    </div>
  );
};

export default FamilyInputs;
