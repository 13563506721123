import React from "react";
import { PersonEdit } from "./PersonEdit";
import FamilyEdit from "./FamilyEdit";
//import { useOutletContext } from "react-router-dom";
import API from "../../API";

interface Props {
  currentHousehold: any;
  setCurrentHousehold: any;
  persons: any;
  setPersons: any;
  setEdit: any;
}

export const EditPopout: React.FC<Props> = ({
  currentHousehold,
  setCurrentHousehold,
  persons,
  setPersons,
  setEdit,
}) => {
  const saveHouseChanges = () => {
    API.saveHouseAndPersons(currentHousehold.id, currentHousehold, persons);
    setEdit(false);
  };
  return (
    <div className="edit_popup">
      <div className="edit_popup_inner">
        <div className="just_flex">
          <div className="btn" onClick={() => saveHouseChanges()}>
            Save Changes
          </div>
          <div className="btn" onClick={() => setEdit(false)}>
            Close
          </div>
        </div>
        <FamilyEdit
          household={currentHousehold}
          setCurrentHousehold={setCurrentHousehold}
        />

        <PersonEdit persons={persons} setPersons={setPersons} />
      </div>
    </div>
  );
};
