import React from "react";
import { HouseholdWith } from "../../models";

interface Props {
  household: HouseholdWith;
  setCurrentHousehold: any;
}

const FamilyEdit: React.FC<Props> = ({ household, setCurrentHousehold }) => {
  const editHousehold = (changes: any) => {
    const newHousehold = { ...household, ...changes };
    setCurrentHousehold(newHousehold);
  };

  return (
    <div>
      <div className="box-for">
        <h3>Family Name</h3>{" "}
        <input
          type="text"
          value={household.household_name}
          onChange={(e) =>
            editHousehold({ household_name: e.currentTarget.value })
          }
        />
      </div>
      <div className="box-for">
        <h3>Street Address </h3>
        <input
          type="test"
          value={household.street_address}
          onChange={(e) =>
            editHousehold({ street_address: e.currentTarget.value })
          }
        />
      </div>
      <div className="box-for">
        <h3>Zip Code</h3>{" "}
        <input
          type="test"
          value={household.zip_code}
          onChange={(e) => editHousehold({ zip_code: e.currentTarget.value })}
        />
      </div>
      <div className="box-for">
        <h3>Phone Number</h3>{" "}
        <input
          type="test"
          value={household.phone_number}
          onChange={(e) =>
            editHousehold({ phone_number: e.currentTarget.value })
          }
        />
      </div>
      <div className="box-for">
        <h3>Country Of Origin</h3>{" "}
        <input
          type="test"
          value={household.country_of_origin}
          onChange={(e) =>
            editHousehold({ country_of_origin: e.currentTarget.value })
          }
        />
      </div>
    </div>
  );
};

export default FamilyEdit;
